    

    <ng-container  *ngIf="param.type === 'number'">
        
      <div class="number_input">
        <mat-form-field >
          <mat-label>{{param.name}}</mat-label>
            <input 
            
            matInput
            [ngModel] = "opnode.params[paramid]"
            [formControl]="fc"
            type = "number"
            [name]=param.name
            [min]=param.min
            [max]=param.max
            step = 1
            (change)= "onParamChange(fc.value)"
        >

        <!-- <mat-hint>{{param.name}}</mat-hint> -->

        </mat-form-field>
      </div>

   </ng-container>

  <ng-container  *ngIf="param.type == 'select'">
    <div class="select-input">
    <mat-form-field>

      <mat-label>{{param.name}}</mat-label>

        <mat-select 
            [ngModel] = "opnode.params[paramid]"
            [formControl]="fc"
            (selectionChange)='onParamChange(fc.value)'>
          <mat-option *ngFor="let opt of selectparam.selectlist" [value]="opt.value">
            {{opt.name}}
          </mat-option>
        </mat-select>


     
    </mat-form-field>
  </div>
 </ng-container>

  <ng-container  *ngIf="param.type == 'boolean' || param.type == 'notation_toggle'">
    <div class="boolean-input">


      <mat-form-field>

        <mat-label>{{param.name}}</mat-label>

        <mat-select 
            [ngModel] = "opnode.params[paramid]"
            [formControl]="fc"
            (selectionChange)='onParamChange(fc.value)'>

          <mat-option [value]="1">
            {{boolparam.truestate}}
          </mat-option>

          <mat-option [value]="0">
            {{boolparam.falsestate}}
          </mat-option>

        </mat-select>
     
    </mat-form-field>


  </div>
</ng-container>

<ng-container  *ngIf="param.type == 'string'">
  <div class="string-input">
    <mat-form-field>
      <mat-label>{{param.name}}</mat-label>

      <!-- <input 
      matInput
      [formControl]="fc"
      [matTooltip]="description"      
      [errorStateMatcher]="regexValidator"
      (change)= "onParamChange(fc.value)" > -->

      <input 
      matInput
      [formControl]="fc"
      [matTooltip]="description" 
      [(ngModel)] = "opnode.params[paramid]"
      (keyup.enter) =  "_updateString(fc.value)" 
       >
       

      @if (fc.hasError('required') ) {
        <mat-error>An input is required</mat-error>
      }
      @if (fc.hasError('pattern')) {
        <mat-error>the current input is not correctly formatted </mat-error>
      }

    </mat-form-field> 
    <button
    class="refresh"
    [disabled]="!refresh_dirty"
    (click)="_updateString(fc.value)" 
    mat-mini-fab 
    aria-label="Example icon button with a menu icon">
   <i class="fa fa-refresh" aria-hidden="true"></i>
   </button>


  </div>
</ng-container>

      
<ng-container  *ngIf="param.type == 'code'">
  <div class="code-input">
    <mat-form-field>

      <textarea matInput
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="1"
            cdkAutosizeMaxRows="5">{{opnode.params[paramid]}}</textarea>




      <!-- <input 
      matInput
      [formControl]="fc"
      [matTooltip]="description" 
      [(ngModel)] = "opnode.params[paramid]"
       > -->
      <mat-hint>{{param.name}}</mat-hint>



    </mat-form-field> 
    <button mat-flat-button>run</button>

  </div>
  <div class="canvas-container" id="testing123">
  </div>
</ng-container>



    <ng-container  *ngIf="param.type == 'file'">       
      <div class='file-input-param'>
        <div class="form-and-warning">
          <button mat-raised-button [class.show]="has_image_uploaded"  [class.hide]="!has_image_uploaded" 
          (click)="replaceImage()" >Replace Image</button>


          <upload-form 
          [class.show]="!has_image_uploaded"  
          [class.hide]="has_image_uploaded"
            type="init" 
            multiple="false"
            accepts=".jpg,.png,.bmp"
            type="indexed_color_image"
            (onData)="handleFile($event)"
            (onError)="handleError($event)"
            class="upload-form-box">
          </upload-form>
          
          <div class="file-label">
            <div class="text-err" *ngIf="filewarning !== ''">{{filewarning}}</div>
          </div>
        </div>
   



           
        <div class='param-image' [class.show]="has_image_uploaded" [class.hide]="!has_image_uploaded" id="param-image-{{opid}}" >
          <button 
            color="accent" 
            mat-raised-button
            (click)="openImageEditor()"
            >View/Edit Image</button>
        </div>

     
        
      
      
    </div>



   
  </ng-container>




