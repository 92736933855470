<div *ngIf="tree.getNode(id) !== null && !no_draw" class="connection-container selectable-{{!disable_drag}}" id="scale-{{id}}"
[style.z-index] = "zndx"

>

<button
mat-icon-button
color="accent" 
id="connector-{{id}}" 
[class.hidden] = "!show_disconnect"
(click)="disconnect()"
aria-label="Example icon button with a menu icon">
<i class="fas fa-times"></i></button>


<svg id="svg-{{id}}"  xmlns="http://www.w3.org/2000/svg">

  </svg>


</div>


