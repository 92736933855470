
<h2 mat-dialog-title>Welcome to AdaCAD 4</h2>
<mat-dialog-content>
  <p> 
    It's time! We're updating the primary url (adacad.org) to our latest version of the software: AdaCAD 4! This launch comes alongside updated documentation at <a href="https://docs.adacad.org" target="_blank">docs.adacad.org</a>. You can still use the older version, AdaCAD3, at <a href="https://version3.adacad.org">version3.adacad.org</a>
 
  <div class="container">
    <div class="left">
     Want to learn more about AdaCAD 4?
      <button mat-raised-button (click)="loadDocs()">Browse the Documentation</button>

    </div>
    <div class="right">
      Or, would you prefer to use the older version?
      <button mat-raised-button (click)="loadOlderBeta()" >Go to version3.adacad.org</button>
    </div>
  </div>


</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button cdkFocusInitial color="primary" mat-dialog-close >Close</button>
</mat-dialog-actions>

