<div id='scale-{{id}}' class="operation-parent no-text-select selectable-{{!disable_drag}}"
cdkDrag
(mousedown)="mousedown($event)"
(dblclick)="onDoubleClick()"
(click)='toggleSelection($event)' 
[style.z-index] = "zndx"
(cdkDragMoved)="dragMove($event)"
(cdkDragEnded)="dragEnd($event)"
(cdkDragStarted)="dragStart($event)"
>

<!--cdkDragBoundary=".palette"-->



  <div class="operation-container" [style.background-color]="opdescriptions.getCatColor(category_name)">
        <div class="cxn-row" cdkDragHandle>
            <div  *ngFor="let input of opnode.inlets; let i = index" class="input-group">
              
              <app-inlet 
              [opid]='id'
              [inletid]='i'
              [dynamic]='is_dynamic_op'
              (onInputSelected)="inputSelected($event)"
              (onInputVisibilityChange)="visibilityChange($event)"
              (onConnectionRemoved)="removeConnectionTo($event)"
              (onInletChange)="onInletChange($event)"
              (onInletLoaded)="inletLoaded($event)"
              ></app-inlet>
            </div>
          
        </div>


        <div class="top-row" cdkDragHandle>

        <div class="operation-details">
            <div class="name no-text-select">{{displayname}}</div>
          
        </div>


        <mat-menu #editOptions>


          <button *ngIf="!hasPin()"
          mat-menu-item
          (click)="pinToView()">
          <i class="fa-solid fa-map-pin"></i> pin for view
          </button> 
  

          <button *ngIf="hasPin()"
          mat-menu-item
          color="accent"
          (click)="unpinFromView()">
          <i class="fa-solid fa-map-pin"></i> unpin from view
          </button> 

          <button
          mat-menu-item
          (click)="openInEditor()">
            <i class="fa-solid fa-pen-to-square"></i> open in editor
          </button> 

          <button 
          mat-menu-item
          matTooltip= "Duplicate"
          (click)="duplicate()">
          <i class="fa-solid fa-clone"></i> duplicate
          </button>

          <button 
          mat-menu-item
          (click)="openHelpDialog()">
          <i class="fa-solid fa-circle-info"></i> help
          </button> 

          <button  mat-menu-item
          (click)="saveAsBmp()"
          matTooltip ="Download as Bitmap">
          <i class="fa-solid fa-download"></i> download as bitmap
          </button>
    
          <button  mat-menu-item
          (click)="saveAsPrint()"
          matTooltip ="Download as Printable Image">
          <i class="fa-solid fa-image"></i> download as image
          </button>
    
          <button  mat-menu-item
          (click)="saveAsWif()"
          matTooltip ="Download as .WIF file">
          <i class="fa-solid fa-file"></i> download as .WIF file
          </button>

          <button 
          mat-menu-item
          (click)="delete()">
          <i class="fa-solid fa-times"></i> delete  
  
          </button>

          
         
    
        
        </mat-menu>
       
        <button mat-icon-button
        #trigger
        [matMenuTriggerFor]="editOptions"
        class="is-small fa-solid fa-ellipsis"
        matTooltip ="View Options">
        </button>

      

  

      </div>

      <div class="params">

        
          <ng-container *ngFor="let param of op.params; let i = index">
            
            <app-parameter class="param" 
            [param]='param' 
            [opid]='id' 
            [paramid]='i'
            (onOperationParamChange) = onParamChange($event)
            (onFileUpload) = handleFile($event)
            (preventDrag)="disableDrag()"
            >
          </app-parameter>

          </ng-container>



 

      </div>


      <div class="outputs">
        <app-draftcontainer class="draftrendering from_operation" 
        *ngFor="let child_id of children" 
        [id]="child_id" 
        [dirty]="redrawchildren"
        [hasParent]="true"
        (connectionSelected)="connectionStarted($event)"
        (onDuplicateCalled)="designActionChange($event)"
				(onDeleteCalled)="designActionChange($event)"
        (onDrawdownSizeChanged)="updateOutboundConnections($event)"
        >
        </app-draftcontainer>
      </div>



  </div>
</div>  
