

<appKeycodes
  (onUndo)="undo()"
  (onRedo)="redo()"
  (zoomOut)="zoomOut()"
  (zoomIn)="zoomIn()"
  (updateMixerView)="updateMixerView($event)"
  (updateDetailView)="detailViewChange()"
  (onCopySelections)="onCopySelections()"
  (onPasteSelections)="onPasteSelections()"
  (onDrawModeChange)="drawModeChange($event)"
  (onExplode)="onExplode()"
  (onWindowResize)="updateViewAdjustBar()"
>
</appKeycodes>




<canvas #bitmapImage hidden></canvas>


<div class="view-container">

  <div class="header-container">

    <mat-toolbar class="topbar" >



      <div class="topbar-button-group">

        <div class="logo"></div>


        <span>AdaCAD</span>
        

      
        <button mat-flat-button (click)="openVersionDialog()" color="primary" >version {{current_version}}</button>


        <button mat-icon-button [matMenuTriggerFor]="file"><i class="fa-solid fa-house"></i></button>

        <mat-menu #file="matMenu">
          <button mat-menu-item (click)="loadBlankFile()">New Workspace</button>
          <button mat-menu-item [disabled]="!files.connected || !auth.isLoggedIn" (click)="openAdaFiles(false)">Manage Saved Files</button>
          <button mat-menu-item [matMenuTriggerFor]="openFile">Open File</button >
            <!-- <button mat-menu-item (click)="redo()" >Import Bitmaps</button> -->
            <button mat-menu-item [matMenuTriggerFor]="downloadMenu">Export</button>
            <button mat-menu-item (click)="saveFile()">Save</button>
          <button mat-menu-item (click)="openAboutDialog()">About</button>

        </mat-menu>
        
        <mat-menu #downloadMenu="matMenu">
          <button mat-menu-item (click)="downloadWorkspace('ada')">Current Workspace</button>
          <button mat-menu-item [disabled]="!vs.hasViewer()" (click)="saveDraftAs('bmp')">Selected Draft as Bitmap</button>
          <button mat-menu-item [disabled]="!vs.hasViewer()" (click)="saveDraftAs('jpg')">Selected Draft as Image</button>
          <button mat-menu-item [disabled]="!vs.hasViewer()" (click)="saveDraftAs('wif')">Selected Draft as .WIF</button>
          <!-- <button mat-menu-item (click)="downloadWorkspace('bmp')">All Drafts as Bitmaps</button> -->
        </mat-menu>

        <mat-menu #openFile="matMenu">
          <button mat-menu-item (click)="openNewFileDialog()">From Computer</button>
          <button mat-menu-item [disabled]="!files.connected || !auth.isLoggedIn" (click)="openAdaFiles(true)">From AdaCAD Cloud
            <span *ngIf="!auth.isLoggedIn">you must login to use this feature </span>
          </button>
        </mat-menu>

        <!-- <button mat-icon-button [matMenuTriggerFor]="edit"><i class="fa-solid fa-pen-to-square"></i></button>
        <mat-menu #edit="matMenu">
          <button mat-menu-item (click)="undo()">Undo (ctrl-z)</button>
          <button mat-menu-item (click)="redo()" >Redo</button>

         <button mat-menu-item >Select</button>
          <button mat-menu-item >Copy</button>
          <button mat-menu-item >Paste</button>
          <button mat-menu-item >Add Blank Draft</button>
          <button mat-menu-item >Add Operation</button>
          <button mat-menu-item >Add Comment</button> 
       </mat-menu>  -->

        <button 
            class="fullwidth"
            mat-icon-button
            (click)="openMaterials()">
            <i class="fa-solid fa-palette"></i>
          </button> 
      
      
      
         <button  mat-icon-button (click)="openExamples()"><i class="fa-solid fa-lightbulb"></i></button> 
      
      
      

        <button mat-icon-button [matMenuTriggerFor]="settings"><i class="fa-solid fa-gear"></i></button>

        <mat-menu #settings="matMenu">
          <button mat-menu-item [matMenuTriggerFor]="defaultOrigin">Origin</button>

          <mat-menu #defaultOrigin="matMenu">
            <button *ngFor="let opt of originOptions" [class.active]="this.ws.selected_origin_option === opt.value" mat-menu-item (click)="selectOriginOption(opt.value)">{{opt.view}}</button>
          </mat-menu>

          <button mat-menu-item [matMenuTriggerFor]="defaultLoomType">Loom Type</button>
          <mat-menu #defaultLoomType="matMenu">
            <button *ngFor="let loom of loomOptions" [class.active]="this.ws.type === loom.value" mat-menu-item (click)="selectLoom(loom.value)">{{loom.viewValue}}</button>
          </mat-menu>


          <!-- <button mat-menu-item >Default Draft View</button> -->
          <button mat-menu-item [matMenuTriggerFor]="defaultUnits">Units</button>
          <mat-menu #defaultUnits="matMenu">
            <button *ngFor="let unit of unitOptions" [class.active]="this.ws.units === unit.value" mat-menu-item (click)="selectUnit(unit.value)">{{unit.viewValue}}</button>
          </mat-menu>         

     
      <!-- <button mat-menu-item >Default Draft View</button> -->
      <button mat-menu-item [matMenuTriggerFor]="showDrafts">Show Drafts on Dataflow by Default</button>
      
      <mat-menu #showDrafts="matMenu">
        <button [class.active]="!this.ws.hide_mixer_drafts" mat-menu-item (click)="setDraftsViewable(false)">yes</button>
        <button [class.active]="this.ws.hide_mixer_drafts" mat-menu-item (click)="setDraftsViewable(true)">no</button>
      </mat-menu>    

      <button mat-menu-item [matMenuTriggerFor]="showAdvancedOps">Show Advanced Operations</button>
      <mat-menu #showAdvancedOps="matMenu">
        <button [class.active]="this.ws.show_advanced_operations" mat-menu-item (click)="setAdvancedOperations(true)">yes</button>
        <button [class.active]="!this.ws.show_advanced_operations" mat-menu-item (click)="setAdvancedOperations(false)">no</button>
      </mat-menu>   

      <button mat-menu-item (click)="optimizeWorkspace()">Optimize Workspace</button>


          <!-- <button mat-menu-item >Optimize for Device</button> -->
        </mat-menu>
 
        <button  mat-icon-button [matMenuTriggerFor]="help"><i class="fa-solid fa-question"></i></button> 
        <mat-menu #help="matMenu">
          <button mat-menu-item (click)="openHelp()">Go to the Help Website</button>
          <button mat-menu-item (click)="openBug()">Report a Bug/Request a Feature via GitHub</button>
        </mat-menu>


      </div>

        <div class="topbar-button-group">
          <span class="design_mode">design mode </span>
        <mat-button-toggle-group 
        name="editingMode" 
        [(ngModel)]="selected_editor_mode"
        color="primary"
        class="toggle" 
        aria-label="Editor Style"
        (change)="toggleEditorMode()"
        >
          <mat-button-toggle *ngFor="let mode of editorModes" [value]="mode.value">{{mode.view}}</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      
      <div class="topbar-button-group">
       
        <div class="auth" *ngIf="!files.connected">

          <div mat-stroked-button class="offline" >No Internet Connection, Working in Offline Mode</div>

        </div>

        

       
        <div class="auth" *ngIf="files.connected && (auth.showLogoutButton)">
      

          <button mat-flat-button color="primary" [matMenuTriggerFor]="account"> Hi {{auth.username}} <i class="fa-solid fa-caret-down"></i> </button>

          <mat-menu #account="matMenu">
                      <button mat-menu-item [disabled]="!files.connected || !auth.isLoggedIn" (click)="openAdaFiles(false)">Manage Saved Files</button>
            <button mat-menu-item (click)="logout()">Log Off</button>
          </mat-menu>
        </div>



        <div *ngIf="files.connected && !auth.isLoggedIn">
          <button mat-raised-button color="primary" class="example-full-width login" matTooltip="login" (click) = "openLoginDialog()"><i class="fa fa-sign-in"></i> Log in</button>
        </div>

     
      </div>
      
      </mat-toolbar>

  </div>


<div class="ui-grid">

  <div class="ui-left"     
  [style.width.px]="vas.left"
  >

    <app-mixer 
    class="mixer-container" 
    [class.hide]="selected_editor_mode !== 'mixer'" 
    [class.show]="selected_editor_mode === 'mixer'" 
    #mixer
    id="mixer" 
    cdkScrollable
    (onOpenInEditor)="openInEditor($event)"
    >
    </app-mixer> 

  

    <app-editor id="draftdetail"
    class="detail-container"
    #details 
    [class.hide]="selected_editor_mode !== 'draft'"
    [class.show]="selected_editor_mode === 'draft'" 
    (saveChanges)="saveFile()"
    (cloneDraft)="cloneDraft($event)"
    (createDraft)="generateBlankDraftAndPlaceInMixer($event, 'editor')"
    (updateMixer)="updateMixer()"
      ></app-editor>

      <div class="footer-container">
        <mat-toolbar class="footer">
          
          <div class="footer-left">
            <button 
            [class.hide]="selected_editor_mode !== 'mixer'" 
            [class.show]="selected_editor_mode === 'mixer'" 
            mat-icon-button (click)="zoomToFit()">
              <i class="fa-solid fa-arrows-to-eye"></i>
            </button>
            
            
      
            <button mat-icon-button (click)="zoomOut()"><i class="fas fa-search-minus"></i></button>         
           
            <mat-slider 
              min="0"
              [max]="zs.num_steps"  
              step="1" 
             >
              <input matSliderThumb [value]="getActiveZoomIndex()"  (valueChange)="zoomChange($event)" >
            </mat-slider>
           
        
            <button  mat-icon-button (click)="zoomIn()"><i class="fas fa-search-plus"></i></button>
            
          
            <button  
            mat-icon-button 
            [disabled]="ss.undo_disabled"
            matTooltip="undo"
            (click)="undo()" >
            <i class="fa-solid fa-rotate-left"></i>
            </button>




      
            <button  
            mat-icon-button 
            [disabled]="ss.redo_disabled"
            matTooltip="redo"
            (click)="redo()">
            <i class="fa-solid fa-rotate-right"></i>
            </button>
      
          </div>
      
      
      
          <div class="copypaste" *ngIf="multiselect.selected.length > 0">
           
             <button mat-raised-button color="accent" (click)="onCopySelections()">Copy (ctrl-c)</button>
            <button mat-raised-button color="accent" [disabled]="multiselect.copy === undefined" (click)="onPasteSelections()">Paste (ctrl-c)</button>
          </div>
      
          <div class="copypaste" *ngIf="multiselect.selected.length == 0">
            hold shift and click to select multiple drafts and operators
         </div>
      
      
      
          <div class="footer-right">
            filename: 
                <input 
                class="filename"
                [formControl]="filename_form" 
                matInput 
                placeholder="current filename">
      
      
           <button mat-mini-fab
           [matMenuTriggerFor]="downloadMenu" 
           matTooltip="download workspace"
           color="primary">       
           <i class="fa-solid fa-download"></i> 
          </button>

          <button mat-mini-fab
          matTooltip="share"
          color="accent"
          (click)="share()">       
          <i class="fa-solid fa-share-nodes"></i>
         </button>
      
            </div>
        </mat-toolbar>
      </div>


</div>
<app-viewadjust #viewadjust class="view-adjust-overlay"></app-viewadjust>

<div class="ui-right"  [style.width.px]="vas.right">
     <app-viewer 
     #viewer
     class="static-container"
     (onOpenEditor)="openInEditor($event)"
     (onDraftRename)="updateDraftName($event)"
     (onSave)="saveDraftAs($event)"
     ></app-viewer>



      
</div>




</div>
</div>




  
