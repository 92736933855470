<p mat-dialog-title
cdkDrag
 cdkDragRootElement=".cdk-overlay-pane" 
 cdkDragHandle>
 <span *ngIf="data.selectOnly" >Load Workspaces From AdaCAD</span>
 <span *ngIf="!data.selectOnly">Manage Workspaces</span>



</p>

<mat-dialog-content class="filebrowser">

  <div *ngIf="!auth.isLoggedIn">
    <button mat-flat-button  color="primary" matTooltip="login" (click)="openLoginDialog()"><i class="fa fa-sign-in"></i> Log in</button> to load and save files online
  </div>



  <ng-container *ngIf="!files.connected">
    Saved files cannot be accessed because you are offline.
  </ng-container>


  <div *ngIf="shared_filelist.length > 0" class="shared_files">
   <h3>Your Shared Files</h3>

   <div class="browser_list">
    <div class="file-item-block">
      <div class="filename">
        Name
      </div>
      <div class="actions">
        Actions
      </div>
    </div>



    <div  class="file-item-block" *ngFor="let file of shared_filelist" >
        
          <div class="filename">
             <h4>  {{file.shared.filename}}</h4>
             <p class="desc">{{file.shared.desc}}</p>

          </div>
  

          <div class="lastviewed"> 
            <button mat-raised-button
            (click)="copyToClipboard(file.id)"><i class="fa-solid fa-link"></i></button> 
            </div>


            <!-- <div *ngIf="(file.id == this.files.current_file_id && !rename_mode)"  class="lastviewed">last saved at: {{last_saved_time}} </div> -->


          <div class="actions"> 

            <button mat-raised-button [matMenuTriggerFor]="shared_menu"><i class="fa-solid fa-ellipsis"></i></button>

            <mat-menu #shared_menu="matMenu">
              
              <button 
              mat-menu-item
              (click)="openFile(file.id)"
              ><i class="fas fa-folder-open"></i> open file
             </button>


            <button 
            mat-menu-item
            (click)="editSharedFile(file.id)"
            ><i class="fas fa-edit"></i> edit sharing settings
          </button>


            <button 
            mat-menu-item
            matTooltip="download"
            (click)="exportSharedWorkspace(file.id)">
            <i class="fa-solid fa-download"></i> download file
            </button>

            <button 
            mat-menu-item
            [matMenuTriggerFor]="stopShareConfirm"
            >
            <i class="fa-solid fa-link-slash"></i> stop sharing
            </button>


            </mat-menu>

            <mat-menu #stopShareConfirm>
              <button 
              mat-flat-button
              (click) = "unshare(file.id)"
              >
                Are you sure?
                </button>
            </mat-menu>


          
  
       
  



  
        
    

            <mat-menu #deleteConfirm>
              <button 
              mat-flat-button
              (click) = "remove(file.id)"
              >
                Are you sure?
                </button>
            </mat-menu>
          
          
          </div>
    




            
    
    </div>

   </div>
  </div>

    <h3>All Files</h3>


      <!-- HEADER ROW -->
     <div class="browser_list">
      <div class="file-item-block">
        <div class="filename">
          Name
        </div>
        <div class="lastviewed">
          Last Viewed
        </div>

        <div class="actions">
          Actions
        </div>
      </div>



      <div  class="file-item-block" [class.current]="file.id == this.files.current_file_id" *ngFor="let file of unopened_filelist" >
          
            <div class="filename">
                <mat-form-field *ngIf="rename_mode_id == file.id" appearance="outline">
                  <mat-label>Rename Workspace</mat-label>
                  <input matInput color="accent" [(ngModel)]="rename_file_name" placeholder="Filename" (change)="rename(file.id)"> 

                  <button 
                  matSuffix
                  mat-icon-button 
                  [class.primary]="file.id == this.files.current_file_id" 
                  (click) = "rename(file.id)">
                  <i class="fas fa-save"></i></button>
                </mat-form-field>


                <h4 *ngIf="rename_mode_id !== file.id" >  {{file.meta.name}}</h4>



            </div>
    

            <div *ngIf="file.id == this.files.current_file_id" class="lastviewed"> opened </div>
            <div *ngIf="file.id !== this.files.current_file_id" class="lastviewed"> {{file.meta.date}} </div>


              <!-- <div *ngIf="(file.id == this.files.current_file_id && !rename_mode)"  class="lastviewed">last saved at: {{last_saved_time}} </div> -->


            <div class="actions"> 

              <button mat-raised-button [matMenuTriggerFor]="menu"><i class="fa-solid fa-ellipsis"></i></button>

              <mat-menu #menu="matMenu">
                
                <button 
                [disabled]="file.id ==this.files.current_file_id"
                mat-menu-item
                (click)="openFile(file.id)"
                ><i class="fas fa-folder-open"></i> open
               </button>


              <button 
              mat-menu-item
              (click)="rename(file.id)"
              ><i class="fas fa-edit"></i> rename
            </button>

            <button 
            mat-menu-item
            (click)="duplicate(file.id)"
            ><i class="fas fa-copy"></i> make a copy
          </button>

          <button 
          mat-menu-item
          (click)="shareWorkspace(file.id)">
          <i class="fa-solid fa-share-nodes"></i> share
          </button>


              <button 
              mat-menu-item
              matTooltip="download"
              (click)="exportWorkspace(file.id)">
              <i class="fa-solid fa-download"></i> download file
              </button>

              <button 
              mat-menu-item
              [disabled]="file.id ==this.files.current_file_id"
              [matMenuTriggerFor]="deleteConfirm"
              >
              <i class="fa-solid fa-trash"></i> delete
              </button>


              </mat-menu>


            
    
         
    



    
          
      

              <mat-menu #deleteConfirm>
                <button 
                mat-flat-button
                (click) = "remove(file.id)"
                >
                  Are you sure?
                  </button>
              </mat-menu>
            
            
            </div>
      




              
      
      </div>

       


    <!-- <mat-divider></mat-divider> -->
    <!-- <h2>Publicly Shared Files</h2> -->

      
     
     

      <!-- <div mat-line>filder name | <i class="fa-solid fa-folder"></i><i class="fa-solid fa-folder"></i></div> -->
  

    <!-- <mat-list-item *ngFor="let folder of folders">
      <mat-icon mat-list-icon>folder</mat-icon>
      <div mat-line>{{folder.name}}</div>
      <div mat-line> {{folder.updated | date}} </div>
    </mat-list-item> -->

     </div>


</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button color="primary" mat-dialog-close>Close</button>
</mat-dialog-actions>
