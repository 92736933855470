import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { Bounds, Point, Note } from '../../../core/model/datatypes';
import utilInstance from '../../../core/model/util';
import { NotesService } from '../../../core/provider/notes.service';
import { ViewportService } from '../../provider/viewport.service';
import { ZoomService } from '../../../core/provider/zoom.service';
import { CdkDragMove, CdkDragStart } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.scss']
})
export class NoteComponent implements OnInit {

  //generated by the note service
  @Input()  id: number;
  @Output() deleteNote: any = new EventEmitter();  
  @Output() saveNoteText: any = new EventEmitter();  

  note: Note;


  topleft: Point = {x: 0, y: 0};
  markdown: string = "";
  show_url: boolean = false;
  canvas: HTMLCanvasElement;
  cx: any;
  disable_drag: boolean = false;
  image_url: string = "";
  moveSubscription: Subscription;
  offset: Point = null;


  constructor(
    private notes: NotesService,
    public zs: ZoomService) { 

  }

  ngOnInit() {
    this.note = this.notes.get(this.id);
    if(this.note == undefined){
      this.topleft = {x: 0, y: 0};
    }else{
      this.topleft = {
        x: this.note.topleft.x,
        y: this.note.topleft.y
      }

    }

   
  }

  ngAfterViewInit(){
    this.setPosition(this.topleft)

  }

  setPosition(topleft: Point){
    this.topleft = {x: topleft.x, y: topleft.y};
    this.canvas = <HTMLCanvasElement> document.getElementById("notecanvas-"+this.note.id.toString());
    this.cx = this.canvas.getContext("2d");
    let note_container = document.getElementById('note-'+this.id);
    note_container.style.transform = 'none'; //negate angulars default positioning mechanism
    note_container.style.top =  this.topleft.y+"px";
    note_container.style.left =  this.topleft.x+"px";
  }


  delete(){
    this.deleteNote.emit(this.note.id);
  }

  dragStart($event: CdkDragStart){
    this.offset = null;
  }
    

  dragMove($event: CdkDragMove) {

    let parent = document.getElementById('scrollable-container');
    let note_container = document.getElementById('note-'+this.id);
    let rect_palette = parent.getBoundingClientRect();


    const zoom_factor =  1/this.zs.getMixerZoom();
 
    //this gives the position of
    let op_topleft_inscale = {
      x: note_container.offsetLeft,
      y: note_container.offsetTop
    }

 
    let scaled_pointer = {
      x: ($event.pointerPosition.x-rect_palette.x + parent.scrollLeft) * zoom_factor,
      y: ($event.pointerPosition.y-rect_palette.y+ parent.scrollTop) * zoom_factor,
    }



    if(this.offset == null){

      this.offset = {
        x: scaled_pointer.x - op_topleft_inscale.x,
        y: scaled_pointer.y - op_topleft_inscale.y
      }
      //console.log("LEFT WITH SCALE VS, LEFT POINTER ", op_topleft_inscale, scaled_pointer, this.offset);

    }


    this.topleft = {
      x: scaled_pointer.x - this.offset.x,
      y: scaled_pointer.y - this.offset.y

    }
    note_container.style.transform = 'none'; //negate angulars default positioning mechanism
    note_container.style.top =  this.topleft.y+"px";
    note_container.style.left =  this.topleft.x+"px";

  }


  /**
   * Called when main palette is rescaled and triggers call to rescale this element, and update its position 
   * so it remains at the same coords. 
   * @param scale - the zoom scale of the iterface (e.g. the number of pixels to render each cell)
   */
   rescale(){

  }

  save(){
    this.saveNoteText.emit();
  }


  /**
   * draw onto the supplied canvas, to be used when printing
   * @returns 
   */
   drawForPrint(canvas, cx, scale: number) {

    // if(canvas === undefined) return;
   
   

    // //draw the supplemental info like size
    // cx.fillStyle = "#666666";
    // cx.font = scale*2+"px Verdana";
    // cx.fillText(this.note.text,this.bounds.topleft.x, this.bounds.topleft.y+this.bounds.height + 20 );

  }

  disableDrag(){
    this.disable_drag = true;
  }

  enableDrag(){
    this.disable_drag = false;
  }

  uploadImage(){
    this.show_url = true;

  }

  enterUrl(){
    this.show_url = false;

  }

  colorChange(event: any){
   this.notes.setColor(this.id, event);
  }

  hidePreview(e) { console.log(e.getContent()); }


}
