
<!-- <app-sim-sidebar *ngIf="hasFocus" id="sim_sidebar"
[sim_expanded]="sim_expanded"
(onExpand)="expandSimulation()"
></app-sim-sidebar> -->

<div id="sizeerror">
  <strong>This draft is too large to simulate.</strong> <br>
</div>

<div id="simulation_container">
</div>

  <!-- <div class="controls" [style.display]="(sim_expanded) ? 'flex' : 'none'"> -->
      <!-- <mat-form-field class="example-full-width">
        <mat-label>Space Between Layers</mat-label>
        <input 
        matInput
        [(ngModel)] = "layer_spacing"
        type = "number"
        [min]=0
        [max]=100
        step = 1
        (change)= "changeLayerSpacing($event)">
      </mat-form-field> -->
      
       <!-- <mat-form-field class="example-full-width">
        <mat-label>Radius</mat-label>
        <input 
        matInput
        [(ngModel)] = "radius"
        type = "number"
        [min]=1
        [max]=1000
        step = 1
        (change)= "changeRadius($event)">
      </mat-form-field> --> 

      <!--this number tells the code how many interlacements with a certain layer need to be observed on a single weft  
      for it to "count" as a real value. For small unit drafts, this number can be really low -->
     <!-- <mat-form-field class="example-full-width">
        <mat-label>Layer Threshold</mat-label>
        <input 
        matInput
        [(ngModel)] = "layer_threshold"
        type = "number"
        [min]=1
        [max]="100"
        step = 1
        (change)= "changeLayerThreshold()">
      </mat-form-field>  -->

      <!--this number considered how close two warps, of contrasting interlacement val, need to be together for them to actually be considered an interlacement -->
      <!-- <mat-form-field class="example-full-width">
        <mat-label>Longest Weft Float</mat-label>
        <input 
        matInput
        [(ngModel)] = "max_interlacement_width"
        type = "number"
        [min]=1
        [max]="100"
        step = 1
        (change)= "changeILaceWidth()">
      </mat-form-field> -->

      <!-- <mat-form-field class="example-full-width">
        <mat-label>Longest Warp Float</mat-label>
        <input 
        matInput
        [(ngModel)] = "max_interlacement_height"
        type = "number"
        [min]=1
        [max]="100"
        step = 1
        (change)= "changeILaceHeight()">
      </mat-form-field> -->

      <!-- <button mat-button (click)="snapToX()">Snap X</button> -->

      <!-- <div class="toggle fullwidth" > 
        <mat-slide-toggle
        [(ngModel)]="showing_warp_layer_map"
        (click)="toggleWarpLayerView()">
        Show Warp Layer Map
        </mat-slide-toggle>
      </div> -->
      <!-- <div class="toggle fullwidth" > 
        <mat-slide-toggle
        [(ngModel)]="showing_weft_layer_map"
        (click)="toggleWeftLayerView()">
        Show Weft Layer Map
        </mat-slide-toggle>
      </div> -->

      <!-- <div class="toggle fullwidth" > 
        <mat-slide-toggle
        [(ngModel)]="showing_topo"
        (click)="toggleTopo()"> Show Topography
        </mat-slide-toggle>
      </div> -->

      <!-- <div class="toggle fullwidth" > 
        <mat-slide-toggle
        [(ngModel)]="showing_draft"
        (click)="toggleDraft()"> Show Draft
        </mat-slide-toggle>
      </div> -->

      <!-- <div class="toggle fullwidth" > 
        <mat-slide-toggle
        [(ngModel)]="showing_warps"
        (click)="toggleWarps()">
        Show Warps 
        </mat-slide-toggle>
      </div> -->


      <!-- <div class="toggle fullwidth" > 
        <mat-slide-toggle
        [(ngModel)]="showing_wefts"
        (click)="toggleWefts()"> Show Wefts
        </mat-slide-toggle> 
      </div>  -->
  <!-- </div>  -->
    <!-- <mat-form-field class="example-full-width">
    
     <mat-label>Warp Layer Threshold</mat-label>
    <input 
    matInput
    [(ngModel)] = "warp_threshold"
    type = "number"
    [min]=0
    [max]="draft.drawdown[0].length"
    step = 1
    (change)= "warpThresholdChange()">
  </mat-form-field>  -->
<!-- </div> -->

<!-- <div class="cameracontrols">
  Press and move to rotate the simulation. Press+Control and move will pan the camera. Scroll in/out will zoom in/out
</div> -->
     

 

