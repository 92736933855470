<div id="selection-container-{{id}}" 
class="selection-container-div" 
[class.hasSelection] = "has_selection">

<div id="selection-{{id}}" class="selection">
    
    
    
</div>

<div class="meta" id="selection-meta">
    
    <div class="size-row" id="size-row-id-{{id}}" *ngIf="width !== -1">
        <span>{{width}} x {{height}} </span>
    </div>
    
    <div class="selection-row" id="action-row-id-{{id}}">
        
        
        <button mat-fab
        matTooltip="edit design in this region"
        [matMenuTriggerFor]="actions"
        ><i class="fas fa-magic"></i></button>
        
        <mat-menu #actions="matMenu"
        yPosition="below"
        xPosition="before">
        <ng-container *ngFor="let action of design_actions">
            <button class="menu-button" 
            mat-menu-item 
            (click)="designActionChange(action.value)">
            <span class="{{action.icon}}"></span>
            <span>{{action.viewValue}}</span>
            </button>
        </ng-container>
        </mat-menu>
    </div>
</div>

</div>