<p mat-dialog-title
cdkDrag
 cdkDragRootElement=".cdk-overlay-pane" 
 cdkDragHandle>
 <span *ngIf="editable">View/Edit Image Colors</span>
 <span *ngIf="!editable">View Image Colors</span>
</p>


<mat-dialog-content >

    <div class="content_left">
        <p *ngIf="editable">Use this editor to modify the color space of your image. You can do this grouping colors together that you would like the software to treat as the same.</p>

        <div class="image_preview" id="image_preview">
            <canvas id="preview_canvas"></canvas>
          </div>
        <div class="dims">{{img.width}} wide x {{img.height}} tall</div>
        <div class="dims">filename: {{img.name}}</div>
        <div class="dims">media id: {{media_id}}</div>

    </div>
    <div class="content_right">

        <div class="data">
                
            <div class="mappings">


                <div class="header">
                    <span>original color space ({{img.colors.length}})</span>  
                </div>


                <div *ngFor="let color of color_table; index as i" class="data_row">
                   
                    <!--original swatch-->
                    <div class="swatch" [style.background-color]="color.from_hex">
                        <div class="centered_id">{{color.from}}</div>
                    </div> 
                    
                     <!--color mapped to-->
                    <mat-form-field>
                        <mat-label>groups with:</mat-label>
                        <mat-select [disabled]="!editable" [(value)]="color.to" (selectionChange)="mappingChanged(color.from, $event)">
                            <mat-option *ngFor="let to_color of img.colors; index as i" [value]="i">  
                                <div class="swatch" [style.background-color]="to_color.hex">
                                    <div class="centered_id">{{i}}</div>
                                </div>
                            </mat-option>
                        </mat-select>
                      </mat-form-field>
                    
                     <!--color mapped to-->

                    <!-- <div class="swatch" [style.background-color]="color.to_hex">
                        {{color.to}}
                    </div> -->
                    
                </div>
            </div>
            <div class="results">
      
                <div class="header">
                    <span>resulting color space ({{resulting_color_space.length}})</span>
                </div>
                    <!--resulting swatch-->
                <div *ngFor="let color of resulting_color_space ; index as i" class="swatch" [style.background-color]="color.to_hex">
                    <div class="centered_id"> {{color.to}}</div>                        
                </div> 

            </div>
        </div>
    </div>


    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close>Save and Close</button>
    </mat-dialog-actions>
      


   