 
<div 
	*ngIf="tree.getDraft(id) !== null" 
	id="scale-{{id}}" 
	class="subdraft-parent-container selectable-{{!disable_drag}}" 
	[class.is_selected]="selected_draft_id == id"
	cdkDrag
	(dblclick)="onDoubleClick()"
	(click)="toggleMultiSelection($event)"
	[style.z-index] = "zndx"
	[class.no-pointer] = "disable_drag"
	[cdkDragDisabled] = "disable_drag"
	(cdkDragMoved)="dragMove($event)"
	(cdkDragEnded)="dragEnd($event)"
	(cdkDragStarted)="dragStart($event)"
	> 

 	<div class="topbar" cdkDragHandle >
	</div>


	<app-draftcontainer
	#draftcontainer 
	class="from_subdraft"
	[id]="id" 
	[dirty]="false"
	[hasParent]="false"
	(connectionSelected)="connectionStarted($event)"
	(onOpenInEditor)="openInEditor($event)"
	(onDuplicateCalled)="designAction($event)"
	(onDeleteCalled)="designAction($event)"
	(onRecomputeChildren)="designAction($event)"
	(onDrawdownSizeChanged)="updateOutboundConnections()"
	></app-draftcontainer>


</div>
	

