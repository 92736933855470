<div class="upload-container">
   
   
   <mat-form-field class="upload_form_field">
      <input class="upload_field" matInput type="file" [multiple]="multiple" [accept]="accepts" (change)="detectFiles($event)">
      <div *ngIf="uploading" class="progress-bar">
         <mat-progress-bar mode="determinate" [value]="upSvc.progress"></mat-progress-bar>
      </div>

      <mat-hint>choose a file to upload</mat-hint>
   </mat-form-field>

   <div *ngIf="!uploading" class="upload-button">
      <button mat-raised-button
            color="accent"
            class="upload_button"
            [disabled]="!selectedFiles"
            (click)="upload();">
            Upload
      </button>
   </div>

</div>
<canvas #uploadImage class="hidden"></canvas>