<div class="mixer-sidebars" id="mixer-sidebar" [class.hide]="is_fullscreen">

  <mat-accordion multi>
    <!-- <mat-expansion-panel expanded>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <i class="fas fa-eye"></i>
            View
          </mat-panel-title>

      </mat-expansion-panel-header>

      <button  
      mat-icon-button 
      matTooltip="pan" >
      <i class="fa-solid fa-hand"></i>
      </button>
      
      <button  
        mat-icon-button 
        matTooltip="select">
        <i class="fa-solid fa-hand-pointer"></i>
      </button>
      
  
      
      <button 
       mat-icon-button >
        <i class="fa-solid fa-expand"></i>
      </button>


      
    </mat-expansion-panel>
 -->

<mat-expansion-panel expanded>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <i class="fas fa-edit"></i>
            Edit Workspace
          </mat-panel-title>

      </mat-expansion-panel-header>
      <button 
      mat-stroked-button            
      color="primary"
      matTooltip= "Blank Draft"
      (click)="createNewDraft()">
      + <i class="fas fa-chess-board"></i> add draft
      </button>

      <button 
      mat-stroked-button
      color="primary"
      matTooltip= "Note"
      color="primary"
      (click)="createNewNote()">
      + <i class="fas fa-comment"></i> add note
      </button>
      
    </mat-expansion-panel>



    <mat-expansion-panel expanded>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <i class="fa-solid fa-code"></i>
            Operators
          </mat-panel-title>
  
      </mat-expansion-panel-header>
  


      <mat-form-field matNativeControl class="quickop_form is-fullwidth" appearance="fill" width='100%'  color="primary">
        <mat-label><i class="fas fa-search"></i> search operations</mat-label>
        
        <input type="text"
               placeholder=""
               (keyup.enter)="enter()"
               matInput
               [formControl]="myControl"
               >
        </mat-form-field>
      
        <div>{{search_error}}</div>


        <mat-slide-toggle 
        [checked]="ws.show_advanced_operations"
        (change)="operationLevelToggleChange($event)">
        Show Advanced Operations</mat-slide-toggle>

      
      <ng-container *ngFor="let classification of filteredOptions | async">
            
            <h3>{{classification.class_name}} </h3>  
      
           
      
             <!-- // <ng-container *ngFor="let op_name of class.op_names"> -->
                <ng-container *ngFor="let op_names of classification.ops">
      
              
                      
                       <!-- {{class.description}} -->
                        <button 
                        mat-stroked-button class="operation" 
                        [style.background-color]="op_desc.getCatColor(classification.class_name)" 
                        (click)="addOperation(op_names.name)"
                        [matTooltip]="op_desc.getOpDescription(op_names.name)">
                        <span *ngIf="op_names.advanced"><i class="fa-regular fa-star"></i></span>
                        
                        {{op_names.display_name}}
                      </button>
      

      
              </ng-container>
      
      
              
              
      
          <!-- <p> {{class.description}}</p> -->
        
        </ng-container>
            
          

      </mat-expansion-panel>




</mat-accordion>


</div>


<app-palette  
id="scrollable-container"
class="palette mode-{{dm.cur_mixer_mode}}"
[class.no-pointer]="!palette.pointer_events" 
#palette 
(onOpenInEditor)="openDraftInEditor($event)" >
</app-palette>
