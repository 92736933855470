  
  <!-- <button mat-icon-button (click)="close()"><i class="fas fa-times"></i></button> -->
  
  <h2 mat-dialog-title>Material Library</h2>
  <mat-dialog-content>




<mat-accordion>
<ng-container *ngFor="let shuttle of ms.materials">

    <mat-expansion-panel >

      <mat-expansion-panel-header>
        <mat-panel-title>
          <i [style.color]="shuttle.color" class="fas fa-square"></i> {{shuttle.name}}
          </mat-panel-title>
          <mat-panel-description>
           
        </mat-panel-description>
      </mat-expansion-panel-header>
      
   
      <mat-form-field  class="is-fullwidth">
        <mat-label>color</mat-label>
        <input matInput 
        #colorpicker
        [cpToggle] = 'false'
        [cpSaveClickOutside] = 'true'
        (colorPickerChange) = 'materialColorChange(shuttle.id, $event)' 
        placeholder="Color" 
        class="colorpicker" 
        [(colorPicker)]="shuttle.color" 
        [style.background]="shuttle.color" 
        name="color" 
        [(ngModel)]="shuttle.color"
        cpDialogDisplay ='inline'

        />

      </mat-form-field>

      

      <mat-form-field  class="is-fullwidth">
        <mat-label>name</mat-label>

      <input matInput placeholder="Material Name" name="name" [(ngModel)]="shuttle.name">
    </mat-form-field>

     
    <!-- <mat-form-field class="is-fullwidth">
      <mat-select placeholder="Yarn Type" name="type" [(ngModel)]="shuttle.type">
        <mat-option *ngFor="let type of types" [value]="type.value">
          {{ type.viewValue }}
        </mat-option>
      </mat-select>
    </mat-form-field> -->

      <mat-form-field class="form-field" class="is-fullwidth">
        <mat-label>diameter (in mm)</mat-label>

        <input matInput  
        type="number" 
        placeholder="Yarn Diameter (in mm)" 
        name="diameter" 
        min="0"
        max="100"
        step=".5"
        (change)="diameterChange()"
        [(ngModel)]="shuttle.diameter"> 
        <span matTextSuffix>mm</span>

      </mat-form-field>

     


  <div class="is-fullwidth">
      <mat-form-field class="is-fullwidth">
      <mat-label>Add Notes</mat-label>
      <textarea matInput [(ngModel)]="shuttle.notes" [ngModelOptions]="{standalone: true}">{{shuttle.notes}}</textarea>  	
    </mat-form-field>
  </div>




      <div class="is-fullwidth" *ngIf="ms.materials.length > 1">
     
     
     
        <button 
        class="is-fullwidth" 
        mat-flat-button 
        [matMenuTriggerFor]="replacements"
        > 
        <i class="fa fa-trash"></i> Delete
        </button>

        <mat-menu #replacements="matMenu">
          <button mat-button  *ngFor="let rep of ms.materials"  (click)="delete(shuttle.id, rep.id)">
            {{ rep.name }}
          </button>
        </mat-menu>
        
 
    </div>

</mat-expansion-panel>



</ng-container>

<mat-expansion-panel>

  <mat-expansion-panel-header>
    <mat-panel-title>
      <i class="fas fa-plus" (click) = 'addmaterial = !addmaterial'></i> Add Material
      </mat-panel-title>
      <mat-panel-description>
      
    </mat-panel-description>
  </mat-expansion-panel-header>

  <mat-form-field  class="is-fullwidth">
        
    <input matInput 
    #colorpicker
    placeholder="Color" 
    class="colorpicker" 
    [(colorPicker)]="newshuttle.color" 
    [style.background]="newshuttle.color" 
    name="color" 
    [(ngModel)]="newshuttle.color"
    cpDialogDisplay ='inline'
    cpToggle="true" 

    />

    </mat-form-field>

        <mat-form-field  class="is-fullwidth">
          <mat-label>name</mat-label>

        <input matInput placeholder="Material Name" name="name" [(ngModel)]="newshuttle.name">
      </mat-form-field>

      

      <mat-form-field class="form-field" class="is-fullwidth">
        <mat-label>diameter (in mm)</mat-label>

        <input matInput  
        type="number" 
        placeholder="Yarn Diameter (in mm)" 
        name="diameter" 
        min="0"
        max="100"
        step=".5"
        (change)="diameterChange()"
        [(ngModel)]="newshuttle.diameter"> 
        <span matTextSuffix>mm</span>

      </mat-form-field>


      


    <div class="is-fullwidth">
      <mat-form-field class="is-fullwidth">
      <mat-label>Add Notes</mat-label>
      <textarea matInput [(ngModel)]="newshuttle.notes" [ngModelOptions]="{standalone: true}">{{newshuttle.notes}}</textarea>  	
    </mat-form-field>

    <button mat-flat-button color="primary" (click)="addNewShuttle()"> Add This Material</button>
    </div>
  

</mat-expansion-panel>
</mat-accordion>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close cdkFocusInitial (click)="save()">Save</button>
</mat-dialog-actions>