<form #envt="ngForm" novalidate>

    <mat-form-field class="fullwidth">

    <mat-label>Loom Type</mat-label>
    <mat-select  (selectionChange) ="loomChange(envt)" [(ngModel)]="type" name="loomtype">
      <mat-option *ngFor="let loomtype of loomtypes" [value]="loomtype.value">
        {{loomtype.viewValue}}
      </mat-option>
    </mat-select>
    </mat-form-field>

     
    <mat-form-field >
      <mat-label>Warp Ends</mat-label>            

      <input matInput name="warps" 
      [(ngModel)]="warps" 
      type="number" 
      [min]="1" 
      [max]="100000"
      placeholder="Warp Ends" 
      (change)="warpChange(envt)"
      (blur)="warpChange(envt)"> 
      
   
    </mat-form-field>

    <mat-form-field >
      <mat-label>Weft Picks</mat-label>            

      <input matInput name="wefts" 
      [(ngModel)]="wefts" 
      type="number" 
      min="2" 
      [max]="100000"
      placeholder="Weft Picks" 
      (change)="weftChange(envt)"
      (blur)="weftChange(envt)"> 
   
    </mat-form-field>

    <mat-form-field class="fullwidth">
      <mat-label>Density Units</mat-label>            
      <mat-select [(value)]="units" [(ngModel)]="units" name="units" (selectionChange) ="unitChange()">
        <mat-option *ngFor="let unit of density_units" [value]="unit.value">
          {{unit.viewValue}}
        </mat-option>
      </mat-select>
      </mat-form-field>

      <mat-form-field class="fullwidth">
      <mat-label>Density</mat-label>    
      <input matInput  
      name="epi" 
      [(ngModel)]="epi" 
      type="number" 
      min= "0" 
      (change)="epiChange(envt)"
      (blur)="epiChange(envt)">  

      <span matTextSuffix>
        <ng-container *ngIf="units === 'in'">
          epi
       </ng-container>
       <ng-container *ngIf="units === 'cm'">
        ends / 10cm
      </ng-container> 
 
       </span> 

    
    </mat-form-field>



    <mat-form-field class="fullwidth">
      <mat-label>width</mat-label>
      <input matInput 
      name="width" 
      [(ngModel)]="width" 
      step="0.125" 
      type="number" 
      min= "0.25" 
      max ="60" 
      placeholder="Width" 
      (change)="widthChange(envt)"
      (blur)="widthChange(envt)"> 
      <span matTextSuffix>
       <ng-container *ngIf="units === 'in'">
       inches
      </ng-container>
      <ng-container *ngIf="units === 'cm'">
       cm
     </ng-container> 

      </span> 



    </mat-form-field>



    <ng-container *ngIf="type != 'jacquard'">
      <mat-form-field class="fullwidth" >
        <mat-label >Number of Frames</mat-label>    
        <input matInput 
        name="frames" 
        [(ngModel)]="frames"  
        step="1" 
        type="number" 
        min= "2" 
        max ="1000" 
        (change)="updateMinFrames(envt)" 
        (blur)="updateMinFrames(envt)"> 
         <span matTextSuffix>frames</span> 
       </mat-form-field>


      <mat-form-field class="fullwidth">
        <mat-label>Number of Treadles</mat-label>      
        <input matInput 
        name="treadles" 
        [disabled]="type == 'jacquard'"
        [(ngModel)]="treadles" 
        step="1" 
        type="number" 
        min= "2" 
        max ="1000" 
        (change)="updateMinTreadles(envt)"
        (blur)="updateMinTreadles(envt)"
        > 
         <span matTextSuffix>treadles</span> 
       </mat-form-field>
      </ng-container>

    </form> 
